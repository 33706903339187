var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg-icon", {
    staticClass: "pointer",
    attrs: { name: "ic-more", width: "4", height: "20" },
    on: { click: _vm.execute },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }